import Footer from "../Footer";
import Header from "../Header";
import { Container, Row, Col, Button } from "react-bootstrap";
import NoDisponible from "../../assets/advertencia.png";

const Error404 = () => {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col md="12" className="text-center p-5">
            <img src={NoDisponible} alt="No disponible" className="m-5" />
            <p>No se ha encontrado el vehiculo que esta buscando</p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Error404;
