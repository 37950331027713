import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles/styles.scss";

import Header from "./components/Header";
import Slider from "./components/Slider";
import Main from "./components/Main";
import Footer from "./components/Footer";
import WhatsAppFlotante from "./components/WhatsAppFlotante/WhatsAppFlotante";

import Error404 from "./components/404";

import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { useEffect, useState } from "react";
import { getFirestore } from "./config/firebase";
import { AppProvider } from "./config/ToSendProvider";

function App() {

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const vehicles = db.collection("fiat");
  //   vehicles.get().then((querySnapshot) => {
  //     setVehicles(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   });
  // }, []);

  useEffect(() => {
    const cargarVehiculos = async () => {
      const datosLocales = sessionStorage.getItem("vehicles-hyundai");
      if (datosLocales) {
        setVehicles(JSON.parse(datosLocales));
        setLoading(false);
      } else {
        const db = getFirestore();
        const vehicles = db.collection("hyundai");
        try {
          const querySnapshot = await vehicles.get();
          const vehiclesData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          sessionStorage.setItem("vehicles-hyundai", JSON.stringify(vehiclesData));
          setVehicles(vehiclesData);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };

    cargarVehiculos();
  }, []);

  return (
    <AppProvider>
      <Router basename="/hyundai">
        <ScrollToTop />
        <Switch>
          <Route exact path="/:tipo?">
            <Header vehicles={vehicles} loading={loading} />
            <Slider />
            <Main vehicles={vehicles} loading={loading} />
            <Footer />
            <WhatsAppFlotante />
          </Route>

          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
