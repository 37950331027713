import { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getFirestore } from "../../config/firebase";
import Loading from "../Loading/Loading";

const Slider = ({ tipo }) => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  // console.log(location.pathname.split("/").pop(), "el tipo");

  // useEffect(() => {
  //   const obtenerDatos = async () => {
  //     const db = getFirestore();
  //     try {
  //       const data = await db.collection("sliders-fiat").get();
  //       const arrayData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //       setLoading(false);
  //       //console.log(arrayData);
  //       setBanners(arrayData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   obtenerDatos();
  // }, []);

  useEffect(() => {
    const obtenerDatos = async () => {
      // Verificar si los datos ya están ensessionStorage
      const datosLocales = sessionStorage.getItem("sliders-hyundai");
      if (datosLocales) {
        // Parsear los datos desessionStorage y establecerlos en el estado
        setBanners(JSON.parse(datosLocales));
        setLoading(false);
      } else {
        const db = getFirestore();
        try {
          const data = await db.collection("sliders-hyundai").get();
          console.log("la data que viene en sliders es", data)
          const arrayData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          // Guardar los datos obtenidos ensessionStorage
          sessionStorage.setItem("sliders-hyundai", JSON.stringify(arrayData));
          // Actualizar el estado con los nuevos datos
          setBanners(arrayData);
        } catch (error) {
          console.error("Error al obtener datos de Firestore: ", error);
        } finally {
          // Asegurar que setLoading se establezca en false independientemente del resultado
          setLoading(false);
        }
      }
    };

    obtenerDatos();
    // La dependencia [] significa que este efecto solo se ejecutará una vez, cuando el componente se monte.
  }, []);

  //console.log(banners[0]);

  return loading ? (
    <Loading styles="mt-5 pt-5 pb-5" />
  ) : (
    <>
      {location.pathname.split("/").pop() === "todos" && (
        <>
          <div className="d-md-block d-none">
            <Carousel>
              {banners[0] &&
                banners[0].desktop.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
          <div className="d-md-none d-block">
            <Carousel>
              {banners[0] &&
                banners[0].mobile.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
        </>
      )}
      {location.pathname.split("/").pop() === "plan" && (
        <>
          <div className="d-md-block d-none">
            <Carousel>
              {banners[0] && banners[0].desktop.filter((b) => b.plan === true).length > 0
                ? banners[0] &&
                  banners[0].desktop
                    .filter((b) => b.plan === true)
                    .map((item, index) => (
                      <Carousel.Item key={index}>
                        <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                      </Carousel.Item>
                    ))
                : banners[0] &&
                  banners[0].desktop.map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                    </Carousel.Item>
                  ))}
            </Carousel>
          </div>
          <div className="d-md-none d-block">
            <Carousel>
              {banners[0] && banners[0].mobile.filter((b) => b.plan === true).length > 0
                ? banners[0] &&
                  banners[0].mobile
                    .filter((b) => b.plan === true)
                    .map((item, index) => (
                      <Carousel.Item key={index}>
                        <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                      </Carousel.Item>
                    ))
                : banners[0] &&
                  banners[0].mobile.map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                    </Carousel.Item>
                  ))}
            </Carousel>
          </div>
        </>
      )}
      {location.pathname.split("/").pop() === "convencional" && (
        <>
          <div className="d-md-block d-none">
            <Carousel>
              {banners[0] && banners[0].desktop.filter((b) => b.convencional === true).length > 0
                ? banners[0] &&
                  banners[0].desktop
                    .filter((b) => b.convencional === true)
                    .map((item, index) => (
                      <Carousel.Item key={index}>
                        <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                      </Carousel.Item>
                    ))
                : banners[0] &&
                  banners[0].desktop.map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                    </Carousel.Item>
                  ))}
            </Carousel>
          </div>
          <div className="d-md-none d-block">
            <Carousel>
              {banners[0] && banners[0].mobile.filter((b) => b.convencional === true).length > 0
                ? banners[0] &&
                  banners[0].mobile
                    .filter((b) => b.convencional === true)
                    .map((item, index) => (
                      <Carousel.Item key={index}>
                        <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                      </Carousel.Item>
                    ))
                : banners[0] &&
                  banners[0].mobile.map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Peugeot" />
                    </Carousel.Item>
                  ))}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default Slider;
