import { Row, Col } from "react-bootstrap";

const CardOpciones = ({ info, tipo }) => {
  return (
    <>
      {(tipo === "convencional" || tipo === "todos") && info.convencional.valor && (
        <>
          <Row className="pt-2">
            <Col md={6} xs={12}>
              <div className="infoVehicle">
                <p className="titFinanBancariaCuotaPlan text-start pt-1">Financiá Hasta</p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="infoVehicle">
                {info.convencional.valor && <h4 className="h-100 text-end">$ {info.convencional.valor}</h4>}
                {info.convencional.descripcion !== "-" && (
                  <p className="subTitInfoConvencional text-end">{info.convencional.descripcion}</p>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}

      {/* MODO ANTERIOR CON UN SOLO PLAN POR VEHICULO
      
      {(tipo === "plan" || tipo === "todos") && info.plan.valor && (
        <>
          <Row className="pt-2 pb-2">
            <Col md={6} xs={12}>
              <div className="infoVehicle">
                <p className="titFinanBancariaPlanOvalo text-start mb-0">PLAN </p>
                <p className="titFinanBancariaCuotaPlan text-start mb-0">Cuota desde</p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="infoVehicle text-end">
                <h4 className="h-100">$ {info.plan.valor}</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="w-100 border-bottom  pt-2 mb-3"></div>
            </Col>
          </Row>
        </>
      )} */}

      {
        // MODO ACTUAL CON MULTIPLES PLANES POR VEHICULO

        (tipo === "plan" || tipo === "todos") &&
          info.plan.tipo &&
          info.plan.tipo.length > 0 &&
          info.plan.tipo
            .sort((a, b) => a.precio > b.precio)
            .map((v, index) => (
              <Row className="pb-4" key={index}>
                <Col md={12} xs={12}>
                  {" "}
                  <Row className="pt-1 pb-0">
                    <Col md={12} xs={12}>
                      <div className="infoVehicle d-flex justify-content-between">
                        <p className="text-start mb-0 newTitPlanName">PLAN {v.nombre} </p>
                        <p className="newDescPlan text-start mb-0">{v.desc}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="w-100 border-bottom  pt-1 mb-3"></div>
                    </Col>
                  </Row>
                  <Row className="pt-0 pb-3">
                    <Col md={6} xs={12}>
                      <div className="infoVehicle">
                        <p className="titFinanBancariaCuotaPlan text-start mb-0">Cuota desde</p>
                      </div>
                    </Col>
                    <Col md={6} xs={12}>
                      <div className="infoVehicle text-end">
                        <h4 className="h-100">$ {v.precio}</h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
      }
    </>
  );
};

export default CardOpciones;
