import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardOpciones from "../CardOpciones";

import ModalContacto from "../ModalContacto/ModalContacto";

import checkIcon from "../../assets/check-square.png";

const VehicleCard = (vehiculo) => {
  const [modalShow, setModalShow] = useState(false);

  const tipo = vehiculo.tipo;

  return (
    <div>
      {vehiculo.info.convencional.habilitado && vehiculo.nombreTipo === "convencional" ? (
        <section className="card-new-vehicle">
          <div className="card-new-vehicle-header">
            <h2 className="titulo-tarjeta"> {vehiculo.info.convencional.modelo} </h2>
            {vehiculo.info.convencional.version !== "" ? (
              <h3 className="titulo-version-tarjeta"> {vehiculo.info.convencional.version} </h3>
            ) : null}
          </div>
          <div className="card-new-vehicle-imagen">
            <img
              src={vehiculo.info.convencional.main_picture}
              alt={vehiculo.info.convencional.modelo}
              className="img-fluid"
            />
          </div>
          <div className="card-new-vehicle-footer">
            {vehiculo.info.convencional.entrega_inmediata ? (
              <div className="vehicle-footer-entrega-inmediata">
                <p>
                  {" "}
                  <img src={checkIcon} alt="Icon Check" /> ENTREGA INMEDIATA
                </p>
              </div>
            ) : null}

            {vehiculo.info.convencional.descripcion_general.length !== "" ? (
              <div className="vehicle-footer-descripcion-general">
                <p>{vehiculo.info.convencional.descripcion_general}</p>
              </div>
            ) : null}

            {vehiculo.info.convencional.subtitulo_principal !== "" ? (
              <div className="vehicle-footer-subtitulo-principal">
                <p>{vehiculo.info.convencional.subtitulo_principal}</p>
              </div>
            ) : null}

            {vehiculo.info.convencional.descripcion_subtitulo_principal !== "" ? (
              <div className="vehicle-footer-descripcion-subtitulo-principal">
                <p>{vehiculo.info.convencional.descripcion_subtitulo_principal}</p>
              </div>
            ) : null}

            <div className="vehicle-footer-titulo-principal">
              {vehiculo.info.convencional.titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-a">{vehiculo.info.convencional.titulo_principal}</p>
              ) : null}

              {vehiculo.info.convencional.descripcion_titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-b">
                  {vehiculo.info.convencional.descripcion_titulo_principal}
                </p>
              ) : null}

              {vehiculo.info.convencional.descripcion_rojo_titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-c">
                  {vehiculo.info.convencional.descripcion_rojo_titulo_principal}
                </p>
              ) : null}
            </div>
          </div>
          <button
            className="btn-consultar-card"
            id={`${vehiculo.info.convencional.modelo.replace(/\s+/g, "-").toLowerCase()}-${tipo}`}
            onClick={() => setModalShow(true)}
          >
            Consultar
          </button>

          <ModalContacto
            filtrados={vehiculo.filtrados}
            autoSeleccionado={vehiculo.info.convencional}
            tipoPlan="convencional"
            vMarca={vehiculo.info.convencional.marca}
            vModelo={vehiculo.info.convencional.modelo}
            vVersion={vehiculo.info.convencional.version}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      ) : (
        <section className="card-new-vehicle">
          <div className="card-new-vehicle-header">
            <h2 className="titulo-tarjeta"> {vehiculo.info.plan.modelo} </h2>
            {vehiculo.info.plan.version !== "" ? (
              <h3 className="titulo-version-tarjeta"> {vehiculo.info.plan.version} </h3>
            ) : null}
          </div>
          <div className="card-new-vehicle-imagen">
            <img src={vehiculo.info.plan.main_picture} alt={vehiculo.info.plan.modelo} className="img-fluid" />
          </div>
          <div className="card-new-vehicle-footer">
            {vehiculo.info.plan.entrega_inmediata ? (
              <div className="vehicle-footer-entrega-inmediata">
                <p>
                  {" "}
                  <img src={checkIcon} alt="Icon Check" /> ENTREGA INMEDIATA
                </p>
              </div>
            ) : null}

            {vehiculo.info.plan.tipos_planes[0].nombre_plan !== "" ? (
              <div className="vehicle-footer-planes-cuotas">
                {vehiculo.info.plan.tipos_planes.map((plan, index) => {
                  return (
                    <div key={index} className="vehicle-footer-planes-cuotas-card">
                      <div className="vehicle-footer-planes-cuotas-card-header">
                        <p className="cuotas-card-header-titulo">{plan.nombre_plan}</p>
                        <div className="cuotas-card-header-cuotas-desde">
                          <p className="cuotas-card-header-cuotas-desde-nombre">Cuotas desde:</p>
                          <p className="cuotas-card-header-cuotas-desde-precio">{plan.precio}</p>
                        </div>
                      </div>
                      <div className="vehicle-footer-planes-cuotas-card-footer">
                        <p>{plan.descripcion}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {vehiculo.info.plan.descripcion_general.length !== "" ? (
              <div className="vehicle-footer-descripcion-general">
                <p>{vehiculo.info.plan.descripcion_general}</p>
              </div>
            ) : null}

            {vehiculo.info.plan.subtitulo_principal !== "" ? (
              <div className="vehicle-footer-subtitulo-principal">
                <p>{vehiculo.info.plan.subtitulo_principal}</p>
              </div>
            ) : null}

            {vehiculo.info.plan.descripcion_subtitulo_principal !== "" ? (
              <div className="vehicle-footer-descripcion-subtitulo-principal">
                <p>{vehiculo.info.plan.descripcion_subtitulo_principal}</p>
              </div>
            ) : null}

            {vehiculo.info.plan.cuota_desde !== "" ? (
              <div className="vehicle-footer-cuota-desde">
                <p className="primer-parrafo">Cuotas desde:</p>
                <p className="segundo-parrafo">{vehiculo.info.plan.cuota_desde}</p>
              </div>
            ) : null}

            <div className="vehicle-footer-titulo-principal">
              {vehiculo.info.plan.titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-a">{vehiculo.info.plan.titulo_principal}</p>
              ) : null}

              {vehiculo.info.plan.descripcion_titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-b">{vehiculo.info.plan.descripcion_titulo_principal}</p>
              ) : null}

              {vehiculo.info.plan.descripcion_rojo_titulo_principal !== "" ? (
                <p className="vehicle-footer-titulo-principal-c">
                  {vehiculo.info.plan.descripcion_rojo_titulo_principal}
                </p>
              ) : null}
            </div>
          </div>

          <button
            className="btn-consultar-card"
            id={`${vehiculo.info.plan.modelo.replace(/\s+/g, "-").toLowerCase()}-${tipo}`}
            onClick={() => setModalShow(true)}
          >
            Consultar
          </button>

          <ModalContacto
            filtrados={vehiculo.filtrados}
            autoSeleccionado={vehiculo.info.plan}
            vMarca={vehiculo.info.plan.marca}
            vModelo={vehiculo.info.plan.modelo}
            vVersion={vehiculo.info.plan.version}
            tipoPlan="plan"
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      )}
    </div>
  );
};

export default VehicleCard;
