import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoFooter from "../../assets/logoFooter.png";
import { getFirestore } from "../../config/firebase";

const Footer = ({ bg }) => {
  const query = new URLSearchParams(window.location.search);
  if (query) {
    if (query.get("utm_source")) {
      sessionStorage.setItem("utm_source", query.get("utm_source"));
    }
    if (query.get("utm_medium")) {
      sessionStorage.setItem("utm_medium", query.get("utm_medium"));
    }
  }
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  const [redesSociales, setRedesSociales] = useState([]);

  // useEffect(() => {
  //   const obtenerDatos = async () => {
  //     const db = getFirestore();
  //     try {
  //       const data = await db.collection("fiat-informacion").get();
  //       const arrayData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //       setRedesSociales(arrayData[0]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   obtenerDatos();
  // }, []);

  useEffect(() => {
    // Función para cargar datos desde localStorage o Firebase
    const cargarDatos = async () => {
      // Verificar si los datos ya están en localStorage
      const datosLocales = localStorage.getItem("redesSociales-hyundai");
      if (datosLocales) {
        // Parsear los datos de localStorage y establecerlos en el estado
        setRedesSociales(JSON.parse(datosLocales));
      } else {
        // Si no están en localStorage, obtenerlos de Firebase
        const db = getFirestore();
        try {
          const datos = await db.collection("hyundai-informacion").get();
          const arrayDatos = datos.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setRedesSociales(arrayDatos[0]);
          // Almacenar los datos en localStorage
          localStorage.setItem("redesSociales-hyundai", JSON.stringify(arrayDatos[0]));
        } catch (error) {
          console.log(error);
        }
      }
    };

    // Llamar a la función para cargar datos
    cargarDatos();
  }, []);

  const urlActual = window.location.href;

  return (
    <>
      <Container fluid className="footer mt-4">
        <Container>
          <Row>
            <div>
              <Col
                md={12}
                xs={12}
                className="text-sm-start text-center pt-3 d-flex align-items-center gap-5 columnas-foots-centrar-mobile"
              >
                <div className="contenedorTectoCentradoMoble">
                  <Link to={`${urlActual}${utms}`}>
                    <img src={logoFooter} alt="Montironi Peugeot" className="img-fluid" />
                  </Link>
                  <p className="legal ocultar-mobile-legal">
                    El contenido de este sitio web no debe ser considerado oferta y puede ser actualizado <br /> de forma
                    parcial o total sin previo aviso.
                  </p>
                  
                  <div className="contenedorRedesFooter">
                    <p>Seguinos en:</p>
                    <div>
                      {redesSociales.redes &&
                        redesSociales.redes.map((red) => {
                          return red.disponible ? (
                            <a href={red.url} target="_blank" rel="noreferrer" className="px-2">
                              <img src={red.url_icon} alt="Logo" />
                            </a>
                          ) : null;
                        })}
                    </div>
                  </div>
                </div>
                
              </Col>
              <Col md={12} xs={12} className="pt-3 social d-flex align-items-center columna-seguinos-en">
                
              </Col>
              <Col md={12} xs={12} className=" pt-3 social d-flex align-items-center mostrar-mobile-legal">
                <p className="legal">
                  El contenido de este sitio web no debe ser considerado oferta y puede ser actualizado <br /> de forma
                  parcial o total sin previo aviso.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
      </Container>
      <Container fluid className="copyright">
        <Container>
          <Row>
            <Col md={12} xs={12} className="text-center">
              <p>
                Copyright © {new Date().getFullYear()} Montironi Hyundai - Todos los derechos reservados - Políticas de Seguridad

              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
