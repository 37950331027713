import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import VehicleList from "../VehicleList";
import { getFirestore } from "../../config/firebase";
import Loading from "../Loading/Loading";

const Main = ({ vehicles, loading }) => {
  // const [vehicles, setVehicles] = useState([]);
  //const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const db = getFirestore();
  //   const vehicles = db.collection("fiat");

  //   vehicles.get().then((querySnapshot) => {
  //     setLoading(false);
  //     setVehicles(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   });

  //   // itemSearch.get().then((querySnapshot) => {
  //   //   setLoading(false); // Set state -> Render
  //   //   setProducts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   // });
  // }, []);

  // useEffect(() => {
  //   const cargarVehiculos = async () => {
  //     const datosLocales = sessionStorage.getItem("vehicles-fiat");
  //     if (datosLocales) {
  //       setVehicles(JSON.parse(datosLocales));
  //       setLoading(false);
  //     } else {
  //       const db = getFirestore();
  //       const vehicles = db.collection("fiat");
  //       try {
  //         const querySnapshot = await vehicles.get();
  //         const vehiclesData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //         sessionStorage.setItem("vehicles-fiat", JSON.stringify(vehiclesData));
  //         setVehicles(vehiclesData);
  //         setLoading(false);
  //       } catch (error) {
  //         console.log(error);
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   cargarVehiculos();
  // }, []);

  return (
    <Container fluid="xl">
      <Row>
        <Col md={12}>{loading ? <Loading styles="mt-5 pt-5 pb-5" /> : <VehicleList vehicles={vehicles} />}</Col>
      </Row>
    </Container>
  );
};

export default Main;
