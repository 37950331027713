import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import VehicleCard from "../VehicleCard";
import React from "react";
import { Link as Linkscroll } from "react-scroll";

const VehicleList = ({ vehicles }) => {
  const [tipoSearch, setTipoSearch] = useState("todos");
  const [tipoToUse, setTipoToUse] = useState("todos");
  const [filtrados, setFiltrados] = useState(vehicles);

  let history = useHistory();
  let { tipo } = useParams();

  useEffect(() => {
    if (tipo !== "todos" && tipo !== "convencional" && tipo !== "plan") {
      history.push(`/todos`);
    }
  }, []);

  useEffect(() => {
    if (tipo === undefined) {
      setTipoToUse("todos");
    } else {
      setTipoToUse(tipo);
    }
  }, [tipo]);

  useEffect(() => {
    setTipoSearch(tipoToUse);
  }, [tipoToUse]);

  const handleTipo = (e) => {
    setTipoSearch(e);
    history.push(`/${e}`);
  };

  //FILTRAR LOS VEHICULOS
  useEffect(() => {
    if (tipoSearch === "plan") {
      setFiltrados(vehicles.filter((item) => item.plan.habilitado === true));
    }

    if (tipoSearch === "convencional") {
      setFiltrados(vehicles.filter((item) => item.convencional.habilitado === true));
    }

    if (tipoSearch === "todos") {
      setFiltrados(vehicles);
    }
  }, [tipoSearch, vehicles]);

  const filtradosConvencional = vehicles.filter((item) => item.convencional.habilitado === true);

  const filtradosPlan = vehicles.filter((item) => item.plan.habilitado === true);

  // aqui guardamos los datos de utms

  /*sessionStorage.setItem('saludo', 'Bienvenidos asessionStorage');
  valor =sessionStorage.getItem('saludo');
  */

  return (
    <>
      {/* <Row id="scroll-click">
        <Col md={12} className="text-center">
          <h1 className="titMainPortada pt-5 pb-5 text-center">¡Llevate tu vehículo en Montironi!</h1>
        </Col>
      </Row> */}
      <Row className="pb-5" id="todos">
        <Col className="d-flex justify-content-center">
          {/*
            <Linkscroll
            to="scroll-click"
            className={`btn btn-blue m-2 ${tipoSearch !== "plan" && tipoSearch !== "convencional" && "active"}`}
            onClick={() => handleTipo("todos")}
            >
              Ver todo
          </Linkscroll>
          */}
          {/* <Linkscroll
            className={`btn btn-blue m-2 ${tipoSearch === "convencional" && "active"}`}
            onClick={() => handleTipo("convencional")}
            to="scroll-click"
          >
            0 KM
          </Linkscroll>
          <Linkscroll
            className={`btn btn-blue m-2 ${tipoSearch === "plan" && "active"}`}
            onClick={() => handleTipo("plan")}
            to="scroll-click"
          >
            Plan de Ahorro
          </Linkscroll> */}
        </Col>
      </Row>
      {tipoSearch === "convencional" || tipoSearch === "todos" ? (
        <div id="convencional">
          <Row className="d-flex">
            <h2 className="titulo-categorias-home">0KM</h2>
          </Row>
          <Row className="d-flex">
            {filtradosConvencional && filtradosConvencional.length > 0 ? (
              filtradosConvencional
                .sort((a, b) => a.convencional.prioridad - b.convencional.prioridad)
                .map((item, index) => (
                  <Col lg={4} md={6} xs={12} key={index} className="flex-wrap mb-4">
                    <VehicleCard
                      info={item}
                      tipo={tipoSearch}
                      filtrados={filtradosConvencional}
                      nombreTipo="convencional"
                      todosLosVehiculos={vehicles}
                    />
                  </Col>
                ))
            ) : (
              <>
                <p className="text-center pt-5 pb-5">Proximamente más opciones disponibles.</p>
              </>
            )}
          </Row>
        </div>
      ) : null}

      {tipoSearch === "plan" || tipoSearch === "todos" && filtradosPlan && filtradosPlan.length > 0 ? (
        <div className={tipoSearch === "plan" ? null : "mt-5"} id="plan">
          <Row className="d-flex">
            <h2 className="titulo-categorias-home">HYUNDAI PLAN</h2>
          </Row>
          <Row className="d-flex">
            {filtradosPlan && filtradosPlan.length > 0 ? (
              filtradosPlan
                .sort((a, b) => a.plan.prioridad - b.plan.prioridad)
                .map((item, index) => (
                  <Col lg={4} md={6} xs={12} key={index} className="flex-wrap mb-4">
                    <VehicleCard
                      info={item}
                      tipo={tipoSearch}
                      filtrados={filtradosPlan}
                      nombreTipo="plan"
                      todosLosVehiculos={vehicles}
                    />
                  </Col>
                ))
            ) : (
              <>
                <p className="text-center pt-5 pb-5">Proximamente más opciones disponibles.</p>
              </>
            )}
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default VehicleList;
