import { Form, Col, Row, Button, Badge, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import check from "../../assets/checkEnviado.png";
import { useParams } from "react-router-dom";
import loadingConsulta from "../../assets/preloader.gif";
import banderaArgentina from "../../assets/banderaArgentina.png";
import { sendPostRequest } from "../../config/desol";
import { useAppContext } from "../../config/ToSendProvider";

const Formulario = ({
  vMarca,
  vModelo,
  vVersion,
  filtrados,
  autoSeleccionado,
  tipoPlan,
  setFormularioConInformacion,
}) => {
  let { id } = useParams();
  if (id === undefined) {
    id = "No seleccionado";
  }

  let vehiculoSeleccionado = vModelo ? vModelo : "No Seleccionado";

  const query = new URLSearchParams(window.location.search);

  const utm_source = (query && query.get("utm_source")) || sessionStorage.getItem("utm_source");
  const utm_medium = (query && query.get("utm_medium")) || sessionStorage.getItem("utm_medium");
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [tipoTemplate, setTipoTemplate] = useState("landing_hyundai_nueva");
  const [desolChannel, setDesolChannel] = useState("C12_FIAT_JEEP_PEUGEOT_LANDINGS");
  const [status, setStatus] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  // const [toSend, setToSend] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   comments: "",
  //   medio: "Llamada",
  //   horario: "",
  //   vehicle: `${vMarca ? vMarca : ""} ${vehiculoSeleccionado}`,
  //   origen: `${utm_source ? utm_source : ""}  ${utm_medium ? utm_medium : ""}`,
  // });]

  const { toSend, setToSend } = useAppContext();


  useEffect(() => {
    setToSend((prev) => ({
      ...prev,
      vehicle: `${vMarca ? vMarca : ""} ${vehiculoSeleccionado}`,
      origen: `${utm_source ? utm_source : ""}  ${utm_medium ? utm_medium : ""}`,
    }));
  }, []);

  const handleChange = (e) => {
    // setToSend({ ...toSend, [e.target.name]: e.target.value });

    setToSend((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (toSend.name !== "" || toSend.email !== "" || toSend.phone !== "") {
      setFormularioConInformacion(false);
    } else {
      setFormularioConInformacion(false);
    }

    if (tipoPlan === "plan") {
      setTipoTemplate("comercialHyundai");
      setDesolChannel("C12_FIAT_JEEP_PEUGEOT_LANDINGS");
    } else {
      setTipoTemplate("comercialHyundai");
      setDesolChannel("C12_FIAT_JEEP_PEUGEOT_LANDINGS");
    }

    if (e.target.name === "phone") {
      // Remove spaces and check if the length is less than 10
      console.log("aca");
      const isValid = e.target.value.replace(/\s+/g, "").length >= 10;
      setIsPhoneValid(isValid);
    }
  };


  const onSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior at the start
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // console.log("no valido");
      setShow(true);
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.preventDefault();

      setStatus("cargando");
      setValidated(true);

      const data = {
        Apellido: toSend.name,
        Email: toSend.email,
        Telefono: toSend.phone,
        Canal: tipoPlan === "plan" ? "Plan de ahorro" : "Venta directa",
        Motivo: toSend.comments,
        OrigenLead: `${process.env.REACT_APP_LANDING} - ${utm_source}`,
        CommercialCampaign: utm_medium,
        Campaign: desolChannel,
        Modelo_Pedido: toSend.vehicle,
      };


      // API DESOL
      emailjs.send("landingMontironi", 'landing_hyundai_nueva', toSend, "user_EIC1ELQk7I3NPaFMEDa64")
        .then((response) => {
          setStatus("ok");
          setTimeout(() => {
            setValidated(false);
            setToSend({
              ...toSend,
              name: "",
              email: "",
              phone: "",
              comments: "",
              medio: "",
              horario: "",
              vehicle: "",
              origen: "",
            });
            window.location.href = "https://oficial.montironi.com/hyundai";
          }, 10000);
        })
        .catch((error) => {
          setStatus("error");
          setTimeout(() => {
            setStatus(null);
          }, 10000);
        });
    }
  };

  const handleKeyDown = (event) => {
    // Allow: backspace, delete, tab, escape, enter, and arrow keys
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "Escape",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
    ];
    if (allowedKeys.includes(event.key)) return;

    // Prevent default if not a number
    if (!/^\d+$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {status === null && (
        <>
          <p className="titulo-formulario">
            Vas a consultar por: <b style={{ textTransform: "uppercase" }}>{vehiculoSeleccionado}</b>
          </p>
          <Form onSubmit={onSubmit} noValidate validated={validated}>
            <Row>
              <Col md={12} className="espacio-inpust-mobile">
                <label className="label-formulario-new ps-0 ms-0 pb-1">Nombre y apellido*</label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  className="input-new-formulario"
                  placeholder="Nombre completo"
                  value={toSend.name}
                  onChange={handleChange}
                  isValid={toSend.name !== "" && toSend.name.length > 0}
                  required
                />

                <Form.Control.Feedback type="invalid">Ingresá tu nombre completo</Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="row-new-formulario pt-4">
              <Col md={6}>
                <label className="label-formulario-new ps-0 ms-0 pb-1">Nro de Celular*</label>
                <div className="contenedor-form-nrocelular">
                  <div className="contenedor-form-nrocelular-bandera input-new-formulario">
                    <img src={banderaArgentina} alt="Bandera Argentina" />
                    <span>+549</span>
                  </div>

                  <div>
                    <Form.Control
                      id="phone"
                      name="phone"
                      type="text"
                      minLength={10}
                      className="input-new-formulario"
                      placeholder="351 1234567890"
                      value={toSend.phone}
                      onChange={handleChange}
                      isInvalid={!isPhoneValid}
                      isValid={toSend.phone !== "" && isPhoneValid}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <p className="label-formulario-new-phone mb-0 mt-0 " style={{ fontSize: "12px" }}>
                  Ingresá tu número sin 0 y sin 15.
                </p>
              </Col>
              <Col md={6} className="espacio-inpust-mobile">
                <label className="label-formulario-new ps-0 ms-0 pb-1">E-mail*</label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  className={`input-new-formulario ${
                    toSend.email !== "" && !toSend.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && "is-invalid"
                  }`}
                  placeholder="ejemplo@gmail.com.ar"
                  value={toSend.email}
                  onChange={handleChange}
                  isInvalid={toSend.email !== "" && !toSend.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
                  isValid={toSend.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
                  required
                  pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$"
                />

                <Form.Control.Feedback type="invalid">Ingresá un email válido</Form.Control.Feedback>
              </Col>

              {/* <Col md={6}>
                <label className="label-formulario-new-select">Seleccione la opción por la cual consulta:</label>
                <Form.Select
                  id="vehicle"
                  name="vehicle"
                  className="formaContacto input-new-formulario"
                  onChange={handleChange}
                  required
                >
                  <option selected={true} className="text-uppercase">
                    Seleccione un vehículo
                  </option>
                  {filtrados.map((item) => {
                    let idmapeado;

                    if (tipoPlan === "convencional") {
                      idmapeado =
                        item.id.toUpperCase() === "CRONOS DRIVE" ? "CRONOS LIKE 1.3 GSE" : item.id.toUpperCase();
                    } else {
                      idmapeado = item.id.toUpperCase();
                    }

                    let isSelected;

                    if (idmapeado === "CRONOS STILE - PRECISION" && vVersion?.toUpperCase() === "STILE - PRECISION") {
                      isSelected = true;
                    }

                    if (idmapeado === "CRONOS LIKE 1.3 GSE" && vVersion?.toUpperCase() === "LIKE 1.3 GSE") {
                      isSelected = true;
                    }

                    if (idmapeado !== "CRONOS LIKE 1.3 GSE" && idmapeado !== "CRONOS STILE - PRECISION") {
                      isSelected = vehiculoSeleccionado.toUpperCase() === idmapeado ? true : false;
                    }

                    return (
                      <option value={idmapeado} id={idmapeado} name={idmapeado} key={idmapeado} selected={isSelected}>
                        {idmapeado}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col> */}
            </Row>

            <Row>
              <Col md={12}>
                <label className="label-formulario-new ps-0 ms-0 pb-1">Dejanos tu consulta</label>

                <Form.Control
                  as="textarea"
                  rows={3}
                  className="form-control input-new-formulario"
                  placeholder="Hola, quiero información sobre.."
                  name="comments"
                  id="comments"
                  rows="2"
                  onChange={handleChange}
                  value={toSend.comments}
                  Add
                  the
                  onKeyDown
                  event
                  handler
                  here
                />
              </Col>
            </Row>
            {/* 
            <Row>
              <Col md={12}>
                <label className="label-formulario-new-horario">Quiero que me contacten por:</label>
                <div className="form-checks-formulario">
                  <Form.Check
                    id="medio-llamada"
                    name="medio"
                    className="formaContacto"
                    inline
                    label="Llamada"
                    type="radio"
                    onChange={handleChange}
                    value="Llamada"
                    checked={toSend.medio === "Llamada"}
                  />
                  <Form.Check
                    id="medio-whatsapp"
                    name="medio"
                    className="formaContacto"
                    inline
                    label="Whatsapp"
                    type="radio"
                    onChange={handleChange}
                    value="Whatsapp"
                    checked={toSend.medio === "Whatsapp"}
                  />
                </div>
              </Col>
            </Row> */}

            <Row>
              <Col md={12}>
                <Alert variant={"dark"} dismissible show={show} onClose={() => setShow(false)} className="mt-3 mb-0">
                  <p>Para poder enviar tu consulta necesitamos que completes todos los campos obligatorios *</p>
                </Alert>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="pb-3">
                <button type="submit" className="btn-blue w-100 btn-enviar-formulario mt-3">
                  Enviar Consulta
                </button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      {status === "cargando" && (
        <div className="msgHeader text-center pt-5 pb-5" id="enviando-mensaje">
          <img src={loadingConsulta} alt="Cargando..." className="pb-3" width={250} />
          <p className="texto-loading-consulta">Estamos enviando tu consulta...</p>
        </div>
      )}

      {status === "ok" && (
        <div className="msgHeader text-center pt-5 pb-5" id="mensaje-enviado">
          <div>
            <img src={check} alt="Enviado" className="img-fluid" style={{ marginBottom: "20px", maxWidth: "60px" }} />
            <p className="titulo-formulario-enviado">Ya enviamos tu solicitud.</p>
            <p className="subtitulo-formulario-enviado">Un asesor se contactará con vos a la brevedad. </p>
          </div>
        </div>
      )}

      {status === "error" && (
        <div className="msgHeader pt-5 pb-5" id="mensaje-error">
          <div>
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              Se ha producido un error
            </p>
            <p style={{ textAlign: "center" }}>Vuelva a intentarlo mas tarde</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Formulario;
