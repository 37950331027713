import React, { useEffect, useState } from "react";
import { getFirestore } from "../../config/firebase";
import logoWhatsapp from "../../assets/logoWhatsapp.png";
import "./whatsapp.scss";

const WhatsAppFlotante = () => {
  const [numerosWhatsapp, setNumerosWhatsapp] = useState([]);
  const [mostrarWhatsapp, setMostrarWhatsapp] = useState(false);

  const [whatsappIndividual, setWhatsappIndividual] = useState(null);

  // useEffect(() => {
  //   const db = getFirestore();
  //   const vehicles = db.collection("fiat-informacion");

  //   vehicles.get().then((querySnapshot) => {
  //     const datosCompletos = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));
  //     setWhatsappIndividual(datosCompletos[1].url);
  //     setNumerosWhatsapp(datosCompletos[1].numeros);
  //     setMostrarWhatsapp(datosCompletos[1].mostrar);
  //   });
  // }, []);

  useEffect(() => {
    const cargarDatosInformacion = async () => {
      // Intentar recuperar los datos desessionStorage
      const datosLocales = sessionStorage.getItem("hyundai-informacion");
      if (datosLocales) {
        // Si los datos existen, parsearlos y establecer los estados correspondientes
        const datosCompletos = JSON.parse(datosLocales);
        setWhatsappIndividual(datosCompletos[1].url);
        setNumerosWhatsapp(datosCompletos[1].numeros);
        setMostrarWhatsapp(datosCompletos[1].mostrar);
      } else {
        // Si no hay datos ensessionStorage, hacer la llamada a Firestore
        const db = getFirestore();
        const vehicles = db.collection("hyundai-informacion");
        try {
          const querySnapshot = await vehicles.get();
          const datosCompletos = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          // Actualizar los estados con los datos de Firestore
          setWhatsappIndividual(datosCompletos[1].url);
          setNumerosWhatsapp(datosCompletos[1].numeros);
          setMostrarWhatsapp(datosCompletos[1].mostrar);
          // Guardar los datos ensessionStorage
          sessionStorage.setItem("hyundai-informacion", JSON.stringify(datosCompletos));
        } catch (error) {
          console.error("Error al obtener datos de Firestore: ", error);
        }
      }
    };

    // Ejecutar la función de carga de datos
    cargarDatosInformacion();
  }, []);

  const [open, setOpen] = useState(false);

  if (!mostrarWhatsapp) {
    return null;
  }

  return (
    <div className="whatsapp-container">
      <div className={open ? "whatsapp-oculto activo" : "whatsapp-oculto"}>
        {numerosWhatsapp.map((numero, index) =>
          numero.habilitado ? (
            <a href={numero.url} target="_blank" key={index} rel="noreferrer">
              <img src={logoWhatsapp} alt="whatsapp" />
              <span>{numero.nombre}</span>
            </a>
          ) : null
        )}
      </div>
      {whatsappIndividual ? (
        // Si whatsappIndividual tiene un valor (no es null, undefined, o cadena vacía), muestra este enlace
        <a className="btn-whatsapp-flotante" href={whatsappIndividual} target="_blank" rel="noreferrer">
          <img src={logoWhatsapp} alt="whatsapp" />
        </a>
      ) : (
        // Si no, muestra este botón
        <button className="btn-whatsapp-flotante" onClick={() => setOpen((prevOpen) => !prevOpen)}>
          <img src={logoWhatsapp} alt="whatsapp" />
        </button>
      )}
    </div>
  );
};
export default WhatsAppFlotante;
