import React, { createContext, useContext, useState } from "react";

// Initialize the context
const AppContext = createContext();

// Create a custom hook to use the context
export const useAppContext = () => useContext(AppContext);

// Provider component
export const AppProvider = ({ children }) => {
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
    medio: "Llamada",
    horario: "",
    // Assuming vMarca and vehiculoSeleccionado are passed somehow or managed within the context
    vehicle: "",
    origen: "",
  });

  // Method to update vehicle and origen based on external variables
  const updateToSend = (vMarca, vehiculoSeleccionado, utm_source, utm_medium) => {
    setToSend((prev) => ({
      ...prev,
      vehicle: `${vMarca ? vMarca : ""} ${vehiculoSeleccionado}`,
      origen: `${utm_source ? utm_source : ""} ${utm_medium ? utm_medium : ""}`,
    }));
  };

  return <AppContext.Provider value={{ toSend, setToSend, updateToSend }}>{children}</AppContext.Provider>;
};