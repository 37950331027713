import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link as Linkscroll, animateScroll as Scroll } from "react-scroll";
import { useParams, useHistory } from "react-router-dom";
import logoHeader from "../../assets/logo.png";
import ModalContacto from "../ModalContacto/ModalContacto";
import { useLocation } from "react-router-dom";

const Header = ({ vehicles }) => {
  // const [vehicles, setVehicles] = useState([]);

  // useEffect(() => {
  //   const vehicles = db.collection("fiat");
  //   vehicles.get().then((querySnapshot) => {
  //     setVehicles(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   });
  // }, []);

  // useEffect(() => {
  //   const cargarVehiculos = async () => {
  //     // Verificar si los datos ya están ensessionStorage
  //     const datosLocales = sessionStorage.getItem("vehicles-fiat");
  //     if (datosLocales) {
  //       // Parsear los datos desessionStorage y establecerlos en el estado
  //       setVehicles(JSON.parse(datosLocales));
  //     } else {
  //       // Si no están ensessionStorage, obtenerlos de Firebase
  //       try {
  //         const db = getFirestore();
  //         const querySnapshot = await db.collection("fiat").get();
  //         const arrayVehicles = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //         setVehicles(arrayVehicles);
  //         // Almacenar los datos ensessionStorage
  //         sessionStorage.setItem("vehicles-fiat", JSON.stringify(arrayVehicles));
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };

  //   // Llamar a la función para cargar datos
  //   cargarVehiculos();
  // }, []);

  const [modalShow, setModalShow] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  let location = useLocation();

  const [tipoSearch, setTipoSearch] = useState("todos");
  const [tipoToUse, setTipoToUse] = useState("todos");
  const [filtrados, setFiltrados] = useState(vehicles);

  let history = useHistory();
  let { tipo } = useParams();

  useEffect(() => {
    if (tipo !== "todos" && tipo !== "convencional" && tipo !== "plan") {
      history.push(`/todos`);
    }
  }, []);

  useEffect(() => {
    if (tipo === undefined) {
      setTipoToUse("todos");
    } else {
      setTipoToUse(tipo);
    }
  }, [tipo]);

  useEffect(() => {
    setTipoSearch(tipoToUse);
  }, [tipoToUse]);

  //FILTRAR LOS VEHICULOS
  useEffect(() => {
    if (tipoSearch === "plan") {
      setFiltrados(vehicles.filter((item) => item.plan.habilitado === true));
    }

    if (tipoSearch === "convencional") {
      setFiltrados(vehicles.filter((item) => item.convencional.habilitado === true));
    }

    if (tipoSearch === "todos") {
      setFiltrados(vehicles);
    }
  }, [tipoSearch, vehicles]);

  const urlActual = window.location.href;

  return (
    <Container fluid className="header">
      <Container>
        <Row className="row-header">
          <Col md={6} xs={12} className="text-sm-start text-center pt-3">
            <Link to={`${urlActual}${utms}`}>
              <img src={logoHeader} alt="Montironi Peugeot" className="img-fluid" />
            </Link>
          </Col>
          <Col
            md={6}
            xs={12}
            className="text-sm-start text-center pt-4 justify-content-md-end justify-content-center d-md-flex d-none"
          >
            {location.pathname === "/todos" ? (
              <Linkscroll to="todos" className="link-header px-sm-3" style={{ cursor: "pointer" }}>
                Modelos
              </Linkscroll>
            ) : (
              <Link to={`/${utms}`} className="link-header px-sm-3">
                Modelos
              </Link>
            )}

            <Button className="link-header" onClick={() => setModalShow(true)}>
              Pedir presupuesto
            </Button>
          </Col>
        </Row>
      </Container>
      <ModalContacto
        filtrados={filtrados}
        tipoPlan="convencional"
        vMarca="Hyundai"
        vModelo={null}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
};

export default Header;
