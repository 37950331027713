import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Formulario from "../Formulario/Formulario";
import IconClose from "../../assets/IconClose.png";
const ModalContacto = (props) => {
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [formularioConInformacion, setFormularioConInformacion] = useState(null);

  const handleContinuar = () => {
    setShowModalConfirmacion(false);
    console.log(props.autoSeleccionado);
  };

  const handleConfirmacionCerrar = () => {
    setShowModalConfirmacion(false);
    setFormularioConInformacion(false);
    props.onHide();
  };

  const handleModalCerrar = () => {
    if (formularioConInformacion) {
      setShowModalConfirmacion(false);
    } else {
      props.onHide();
    }
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="modalContacto">
      <Modal.Header>
      <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-grow-1 text-center d-flex justify-content-center ps-4">
            <Modal.Title id="contained-modal-title-vcenter">Hacé tu consulta</Modal.Title>
          </div>

          <div>
            <img onClick={handleModalCerrar} src={IconClose} alt="Icon Cerrar" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formulario
          setFormularioConInformacion={setFormularioConInformacion}
          filtrados={props.filtrados}
          autoSeleccionado={props.autoSeleccionado}
          tipoPlan={props.tipoPlan}
          vMarca={props.vMarca ? props.vMarca : null}
          vModelo={props.vModelo ? props.vModelo : null}
          vVersion={props.vVersion ? props.vVersion : null}
        />
      </Modal.Body>
      {showModalConfirmacion ? (
        <div className="modalConfirmarCerrar">
          <p className="modalConfirmarCerrar-parrafo">
            Si cerrás esta pestaña no <br /> podremos enviar tu consulta.
          </p>
          <div className="modalConfirmarCerrar-buttons">
            <button onClick={handleConfirmacionCerrar} className="modalConfirmarCerrar-buttons-a">
              Cerrar
            </button>
            <button onClick={handleContinuar} className="modalConfirmarCerrar-buttons-b">
              Continuar
            </button>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default ModalContacto;
